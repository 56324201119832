/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0px;
  border: 1px solid #888;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 10px 16px;
  background-color: #fc2335;
  color: white !important;
}

.modelHeader h2, h3{
  color: white !important;
}

.modal-body {padding: 2px 16px; min-height: 100px;}

.modal-footer {
  padding: 10px 16px;
  min-height: 50px;
  background-color: #fc2335;
  color: white !important;
}

#playlistVideo {
  display: none;
}

@media screen and (min-width: 768px) {
  #playlistVideo {
    display: block;
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  .playlistInfo {
    font-size: 15px !important;
    letter-spacing: 0px !important;
    line-height: 25px !important;
    cursor: pointer;
  }
}
